export const policyDescription =
  "This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy";

export const privacyPolicyData = [
  { title: "Interpretation and Definitions", id: "interpretation" },
  {
    title: "Types of data collected. Usage data",
    id: "using personal data",
  },
  {
    title: "Detailed Information on the Processing of Your Personal Data",
    id: "processing personal data",
  },
  { title: "GDPR Privacy", id: "gdpr" },
  { title: "Facebook Fan Page", id: "facebook fan page" },
  { title: "CCPA/CPRA Privacy Notice", id: "ccpa privacy notice" },
  {
    title:
      "'Do Not Track' Policy as Required by California Online Privacy Protection Act (CalOPPA)",
    id: "do not track",
  },
  {
    title: "Your California Privacy Rights (California's Shine the Light law)",
    id: "your california privacy",
  },
  {
    title:
      "California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)",
    id: "minor users rights",
  },
  { title: "Children's Privacy", id: "childrens privacy" },
  { title: "Face Data Storage Policy", id: "face data storage" },
  { title: "Links to Other Websites", id: "links to other websites" },
  { title: "Deleting your profile and data", id: "deleting flow" },
  { title: "Changes to this Privacy Policy", id: "changes to policy" },
  { title: "Contact Us", id: "contact us" },
];
