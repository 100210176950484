import React from "react";

import { PolicyItemWrapper, PolicyText, PolicyTitle } from "../styled";

export const FaceDataStorage = () => {
  return (
    <PolicyItemWrapper id="face data storage">
      <PolicyTitle>
        Face Data Storage Policy
      </PolicyTitle>
      <PolicyText>
        <b>1. Reasons for Storing Face Data</b> <br />
        We collect and store face data in the form of selfies to allow users to track their progress throughout their Face Yoga journey. 
        By comparing photos taken at the beginning and throughout the course, users can visibly assess the improvements in their facial features and overall results. 
        Storing this data empowers users to monitor changes over time, helping to motivate and encourage continued participation in our programs.
      </PolicyText>

      <PolicyText>
        <b>2. Length of Time Face Data is Stored</b> <br />
        Face data (selfies) is stored for the duration of the user's membership on our platform. 
        This is necessary to maintain accurate tracking of their progress over time. Once a user decides to delete their profile, 
        all associated face data will be permanently removed from our systems. 
        We retain this data only as long as the profile is active to ensure that users can access their personal progress history.
      </PolicyText>

      <PolicyText>
        <b>3. Sharing Face Data with Third Parties</b> <br />
        We do not share any face data with third parties under any circumstances unless we receive explicit, 
        written consent from the individual member. Your privacy is our priority, and no face data will be distributed, 
        sold, or otherwise disclosed without the user's express permission.
      </PolicyText>

      <PolicyText>
        <b>4. Reasons for Sharing Face Data with Third Parties</b> <br />
        In cases where face data is shared, it will only occur with the explicit consent of the user. 
        The sole purpose for such sharing is to showcase the user’s beautiful results and progress achieved through Face Yoga. 
        We may, for example, share anonymized or identified face data (based on the member's consent) to highlight the effectiveness of Face Yoga 
        in marketing materials, testimonials, or case studies. However, this will only be done with the user’s full approval.
      </PolicyText>
    </PolicyItemWrapper>
  );
};
